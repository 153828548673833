import React, { lazy, Suspense } from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
// react library for routing
import { BrowserRouter, Route, Switch } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/css/argon-dashboard-pro-react.css";
import "assets/css/global.css";
import AuthRoute from "components/PrivateRoute/AuthRoute";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import config from "config";
// import DashboardRoute from "components/PrivateRoute/DashboardRoute";
import { UserDataProvider } from "./UserDataContext";
import LoadingPage from "./views/LoadingPage";

const App = lazy(() => import("./views/App"));
const VisualGame = lazy(() => import("./views/VisualGame"));
const Test = lazy(() => import("./views/Test"));
const AppsEvals = lazy(() => import("./views/AppsEvals"));
const Studio = lazy(() => import("./views/Studio"));
const MainDashboard = lazy(() => import("./views/MainDashboard"));
const MainDashboardNew = lazy(() => import("./views/MainDashboardNew"));
const OverviewFlow = lazy(() => import("./views/AutoAI-2/AutoAI"));
const RTQ = lazy(() => import("./views/RTQ"));
const Benchmarks = lazy(() => import("./views/Benchmarks"));
const BenchmarkArenaIndividual = lazy(
  () => import("./views/BenchmarkArenaIndividual"),
);
const BenchmarkArena = lazy(() => import("./views/BenchmarkArena"));
const PromptGame = lazy(() => import("./views/PromptGame"));
const ExternalRedirect = lazy(
  () => import("./views/pages/components/ExternalRedirect"),
);
const AutoBench = lazy(() => import("./views/AutoBench"));
const Finetune = lazy(() => import("./views/Finetune"));
const NewRTQ = lazy(() => import("./views/NewRTQ"));
const BenchmarkData = lazy(() => import("./views/BenchmarkData"));
const AutogenAI = lazy(() => import("./views/AutogenAI/AutogenAI"));
const AutoAI3 = lazy(() => import("./views/AutoAI-3/AutoAI3"));
const DemosIndex = lazy(() => import("./views/demos/DemosIndex"));
const Account = lazy(() => import("./views/Account"));
const Welcome = lazy(() => import("./views/Welcome"));
const Agents = lazy(() => import("./views/Agents"));
const RAGChatbotPage = lazy(() => import("./views/RAGChatbotPage"));
const RAGChatbotPageNew = lazy(() => import("./views/RAGChatbotPageNew"));
const NonRAGChatbotPage = lazy(() => import("./views/NonRAGChatbotPage"));
const CSVChatbotPage = lazy(() => import("./views/CSVChatbotPage"));
const CSVChatbotPageNew = lazy(() => import("./views/CSVChatbotPageNew"));
const PDFChatbotPage = lazy(() => import("./views/PDFChatbotPage"));
const InvoiceChatbotPage = lazy(() => import("./views/InvoiceChatbotPage"));
const Invoice = lazy(() => import("./views/Invoice"));
const InvoiceChatbotPageNew = lazy(
  () => import("./views/InvoiceChatbotPageNew"),
);
const SQLChatbotPage = lazy(() => import("./views/SQLChatbotPage"));
const URLChatbotPage = lazy(() => import("./views/URLChatbotPage"));
const ProductURLChatbotPage = lazy(
  () => import("./views/ProductURLChatbotPage"),
);
const ProductURLChatbotPageNew = lazy(
  () => import("./views/ProductURLChatbotPageNew"),
);

const ProductURLChatbotPageCreate = lazy(
  () => import("./views/ProductURLChatbotPageCreate"),
);
const ProcurementCreate = lazy(
  () => import("./views/Procurement/procurementCreate"),
);
const Vendors = lazy(() => import("./views/Procurement/Vendors"));
const PartCreate = lazy(() => import("./views/Procurement/PartCreate"));
const Parts = lazy(() => import("./views/Procurement/Parts"));
const SearchChatbotPage = lazy(() => import("./views/SearchChatbotPage"));

// Commented out import StepperExample to preserve as per the original format
// const StepperExample = lazy(() => import("./views/Stepper"));

const AuthLayout = lazy(() => import("layouts/Auth.js"));
// Additional lazy loaded layouts with comments preserved
// const HomeLayout = lazy(() => import("layouts/Home.js"));
// const PlaygroundLayout = lazy(() => import("layouts/Playground.js"));
// const IndexView = lazy(() => import("views/Index.js"));
const IndexViewNew = lazy(() => import("views/Index-new-3.js"));
const TermsView = lazy(() => import("views/Terms.js"));
const PrivacyView = lazy(() => import("views/Privacy.js"));

const rootElement = document.getElementById("root");

createRoot(rootElement).render(
  <GoogleOAuthProvider clientId={config.GoogleSSO_CLIENT_ID}>
    <UserDataProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            {/*<PrivateRoute path="/admin" component={AdminLayout} />*/}
            <AuthRoute path="/auth" component={AuthLayout} />
            {/*<DashboardRoute path="/dashboard" component={DashboardLayout} />*/}
            {/* <Route path="/resources" component={ResourceLayout} /> */}
            {/* <Route path="/playground" component={DashboardLayout} /> */}
            {/* <Route path="/playground" component={PlaygroundLayout} /> */}
            {/*<Route path="/home" component={HomeLayout} />*/}
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute path="/welcome" component={Welcome} />
            <PrivateRoute path="/demos" component={DemosIndex} />
            <Route path="/terms" component={TermsView} />
            {/* <Route path="/stepper" component={StepperExample} /> */}
            <Route path="/privacy" component={PrivacyView} />
            <Route path={"/game"} component={VisualGame} />
            <Route path={"/prompt-game"} component={PromptGame} />
            <Route path={"/studio"} component={Studio} />
            <Route path={"/autobench"} component={AutoBench} />
            <Route path={"/finetune"} component={Finetune} />
            <Route path={"/evaluation/:app_id/:test_id"} component={Test} />
            <Route path={"/evaluation"} component={AppsEvals} />
            <Route path={"/rtq/:app_id"} component={RTQ} />
            <Route path={"/monitoring"} component={NewRTQ} />
            <Route path={"/dashboard/:id"} component={App} />
            <Route path={"/dashboardold"} component={MainDashboard} />
            <Route path={"/dashboard"} component={MainDashboardNew} />
            <PrivateRoute path={"/autoai"} component={OverviewFlow} />
            <PrivateRoute path={"/auto_ai"} component={AutoAI3} />
            <PrivateRoute
              path={"/create-a-ragchatbot"}
              component={RAGChatbotPage}
            />
            <PrivateRoute
              path={"/create-a-ragchatbot-new"}
              component={RAGChatbotPageNew}
            />
            <PrivateRoute
              path={"/create-a-non-rag-chatbot"}
              component={NonRAGChatbotPage}
            />
            <PrivateRoute
              path={"/create-a-csv-chatbot"}
              component={CSVChatbotPage}
            />
            <PrivateRoute
              path={"/create-a-csv-chatbot-new"}
              component={CSVChatbotPageNew}
            />
            <PrivateRoute
              path={"/create-a-pdf-chatbot"}
              component={PDFChatbotPage}
            />
            <PrivateRoute
              path={"/create-an-invoice-chatbot"}
              component={InvoiceChatbotPage}
            />
            <PrivateRoute path={"/invoice-details"} component={Invoice} />
            <PrivateRoute
              path={"/create-an-invoice-chatbot-new"}
              component={InvoiceChatbotPageNew}
            />
            <PrivateRoute
              path={"/create-a-sql-chatbot"}
              component={SQLChatbotPage}
            />
            <PrivateRoute
              path={"/create-a-url-plugin-chatbot"}
              component={URLChatbotPage}
            />
            <PrivateRoute
              path={"/create-a-product-chatbot"}
              component={ProductURLChatbotPage}
            />
            <PrivateRoute
              path={"/create-a-product-chatbot-new"}
              component={ProductURLChatbotPageNew}
            />
            <PrivateRoute
              path={"/create-a-url-chatbot"}
              component={ProductURLChatbotPageCreate}
            />
            <PrivateRoute
              path={"/procurement/new"}
              component={ProcurementCreate}
            />
            <PrivateRoute
              path={"/procurement/parts/new"}
              component={PartCreate}
            />
            <PrivateRoute
              path={"/procurement/parts/:part_id/vendors"}
              component={Vendors}
            />
            <PrivateRoute
              path={"/procurement/parts"}
              component={Parts}
            />
            <PrivateRoute
              path={"/procurement/search/"}
              component={SearchChatbotPage}
            />
            <Route path={"/autogenai"} component={AutogenAI} />
            <Route path={"/agents"} component={Agents} />
            <Route
              path={"/benchmarks/compare/:benchmark_id"}
              component={BenchmarkArenaIndividual}
            />
            <Route
              path={"/benchmarks/data/:benchmark_id"}
              component={BenchmarkData}
            />
            <Route
              path={"/benchmarks/:benchmark_id"}
              component={BenchmarkArena}
            />
            <Route path={"/benchmarks"} component={Benchmarks} />
            <Route path={"/landing-temp"} component={IndexViewNew} />
            <Route path="/" component={IndexViewNew} exact />
            <Route
              path="*"
              render={() => <ExternalRedirect url="/404.html" />}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </UserDataProvider>
  </GoogleOAuthProvider>,
);
